<template>
  <!-- 
    v-infinite-scroll="handleInfiniteOnLoad"
    class="demo-infinite-container all_main_wrap"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-distance="10"
   -->
  <div class="all_main_wrap">
    <!-- 头部 -->
    <HeaderBox>
      <template #title> 操作记录 </template>
      <template #input>
        <a-input
          v-model="operName"
          placeholder="请输入操作人"
        />
        <a-range-picker
          :disabled-date="disabledDate"
          v-model="selKey"
          @change="onChange"
        />
        <!-- <a-input
          v-model="operName"
          placeholder="请输入操作事件"
        /> -->

        <!-- 选择框固定长度为193px 选择框后缀图标：slot="suffixIcon"-->
        <a-select v-model="title" placeholder="请选择操作模块">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="item.code" v-for="(item,index) in moduleList" :key="index"> {{item.name}} </a-select-option>
        </a-select>
        
        <a-button class="all_btn_solid btn" type="primary" @click="pageNum=1,getMessageList()"
          >搜索</a-button
        >
        <a-button class="all_boder_btn" @click="reset()">重置</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :columns="columns"
        :scroll="{ x: true }"
        :data-source="data"
        :rowKey="(item,index) => index"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNum,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}
        }"
      >
        <template slot="index" slot-scope="item, row, index">
          {{ (pageNum - 1) * pageSize + index + 1 }}
          <!-- {{ index + 1 }} -->
        </template>
        <template slot="title" slot-scope="item, row">
          {{ returnTitle(row) }}
        </template>
        <template slot="businessType" slot-scope="item, row">
          {{ returnBusinessType(row) }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "操作人",
    align: "center",
    dataIndex: "operName",
  },
  {
    title: "操作时间",
    align: "center",
    dataIndex: "operTime",
  },
  {
    title: "操作模块",
    align: "center",
    dataIndex: "title",
    scopedSlots: {
      customRender: "title",
    },
  },
  {
    title: "操作事件",
    align: "center",
    dataIndex: "businessType",
    scopedSlots: {
      customRender: "businessType",
    },
  },
  {
    title: "IP地址",
    align: "center",
    dataIndex: "operIp",
  }
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      selKey: [],
      loading: false,
      moduleList: [], // 模块名称列表
      title: undefined, // 筛选条件 - 模块名称
      operName: '', // 筛选条件 - 操作人
      endDate: '', // 筛选条件 - 结束时间
      startDate: '', // 筛选条件 - 开始时间
      pageNum: 1,
      pageSize: 10,
      total: 0,
      data: [],
      columns
    };
  },
  // 事件处理器
  methods: {
    moment,
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    // 操作事件
    returnBusinessType(e){
      if(!e){
        return ;
      }
      let str = '';
      str = e.businessType + '操作 --- ' + e.requestMethod;
      return str;
    },
    // 操作模块
    returnTitle(e){
      if(!e){
        return ;
      }
      let str = '';
      this.moduleList.map(item=>{
        if(item.code == e.title){
          str = item.name;
        }
      })
      return str;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    disabledRangeTime(_, type) {
      if (type === "start") {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
    //  重置
    reset() {
      this.selKey = [];
      this.title = undefined; // 筛选条件 - 模块名称
      this.operName = ''; // 筛选条件 - 操作人
      this.endDate = ''; // 筛选条件 - 结束时间
      this.startDate = ''; // 筛选条件 - 开始时间
      this.pageNum = 1;
      this.total = 0;
      this.getMessageList();
    },
    // 选择日期
    onChange(date, dateString) {
      if(!dateString.length){
        this.endDate = '';
        this.startDate = '';
        return 
      }
      this.endDate = dateString[1];
      this.startDate = dateString[0];
    },

    onPage(e){
      this.pageNum = e.current
      this.pageSize = e.pageSize
      this.getMessageList()
    },
    // 获取操作记录列表
    getMessageList(){
      this.loading = true;
      this.$ajax({
        url: '/hxclass-management/user/info/getSysOperLogList',
        method:'get',
        params:{
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          endDate: this.endDate,
          operName: this.operName,
          startDate: this.startDate,
          title: this.title
        }
      }).then(res => {
        this.loading = false;
        if(res.code == 200 && res.success){
          this.total = res.data.total;
          this.data = res.data.records;
        }
      })
    },

    // 获取模块名称
    getModuleList(){
      this.$ajax({
        url: '/hxclass-management/user/info/getModule',
        method:'get'
      }).then(res => {
        if(res.code == 200 && res.success){
          this.moduleList = res.data;
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取模块名称列表
    this.getModuleList();
    // 获取操作记录列表
    this.getMessageList()
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.btn {
  margin-right: 24px;
}
.box_con {
  margin-top: 30px;
  .con_item {
    &:nth-last-child(1) {
      .main_item:nth-last-child(1) {
        &::after {
          position: absolute;
          bottom: 0px;
          left: -6px;
          content: "";
          display: inline-block;
          width: 11px;
          height: 11px;
          border-radius: 15px;
          background-color: #2b6ed4;
        }
      }
    }
    .con_date {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      font-weight: bold;
      background-color: #f6f6fc;
      padding: 12px 24px;
    }
    .con_main {
      padding: 10px 10px 0 0;
      margin-top: 10px;
      .main_item {
        position: relative;
        border-left: 1px solid #2b6ed4;
        padding: 0 10px 20px 10px;
        margin-left: 10px;
        &::before {
          position: absolute;
          top: 0px;
          left: -6px;
          content: "";
          display: inline-block;
          width: 11px;
          height: 11px;
          border-radius: 11px;
          background-color: #2b6ed4;
        }
        .item_title {
          color: #333333;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          .title_date {
            font-weight: 400;
          }
          .title_info {
            margin-left: 11px;
            font-weight: 500;
            color: #333333;
          }
        }
        .item_con {
          margin-top: 12px;
          font-size: 12px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          color: #979797;
        }
      }
    }
  }
}
.search-box {
  margin-top: 40px;
  display: flex;
  margin-bottom: 10px;
  .search-input {
    width: 62%;
    margin-right: 24px;
    margin-bottom: 6px;
  }
}

.title1 {
  font-size: 12px;
  font-family: PingFang SC-Regular;
  font-weight: 400;
  color: #979797;
    margin-left: 15px;
  line-height: 14px;
}
</style>
